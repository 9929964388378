import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Empresa, Imóvel } from "smart-imob-types";

const CookieBoxStyle = styled.div<ICookieBox>`
  background: #fff;
  border-radius: 6px;
  bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 2.5%;
  margin: 0px auto;
  padding: 15px;
  position: fixed;
  width: 95%;
  z-index: 100000;
  span {
    color: #282828;
    /* width: 80%; */
    a {
      color: ${props => props.empresa.palleta.cor_primaria || "#252525"};
      font-weight: 600;
      margin-right: 15px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .link-container {
    display: flex;
    width: 100%;
  }
  .btn-accept {
    background: ${props => props.empresa.palleta.cor_primaria || "#252525"};
    /* border-radius: 25px; */
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    transition: 200ms ease-in-out;
    cursor: pointer;
    &:hover {
      background: #fff;
      color: ${props => props.empresa.palleta.cor_primaria || "#252525"};
    }
  }
  @media screen and (max-width: 560px) {
    left: 1%;
    justify-content: space-between;
    width: 98%;
  }
`;

interface ICookieBox {
  empresa: Empresa;
}

const CookieBox = (props: ICookieBox) => {
  const [Accepted, setAccepted] = useState(false);

  const onAccept = () => {
    localStorage.setItem("termos_aceitos", "true");
    setAccepted(true);
  };

  useEffect(() => {
    if (localStorage.getItem("termos_aceitos")) {
      setAccepted(true);
    }
  }, []);
  if (!Accepted) {
    return (
      <CookieBoxStyle empresa={props.empresa}>
        <span>
          Utilizamos cookies para garantir a melhor experiência em nosso site.
          Se você continuar a usar este site, assumiremos que você está
          satisfeito com ele.
          <div className="link-container">
            <Link to="/politica-de-privacidade">Política de Privacidade</Link>
            <Link to="/termos-de-uso">Termos de Uso</Link>
          </div>
        </span>
        <div onClick={onAccept} className="btn btn-yellow">
          Aceitar
        </div>
      </CookieBoxStyle>
    );
  } else {
    return null;
  }
};

export default CookieBox;
