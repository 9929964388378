export const SET_EMPRESA_ATUAL = "empresa/SET_EMPRESA_ATUAL";

const initialState = {
  empresa: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPRESA_ATUAL:
      return {
        ...state,
        empresa: action.data
      };

    default:
      return state;
  }
};

export const removeEmpresaAtual = () => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_EMPRESA_ATUAL,
      data: {}
    });

    resolve({});
  });
