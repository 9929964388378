export const BuilderImgItems = new Set<HTMLImageElement>()
export const BuilderTextHoverItems = new Set<HTMLElement>()
export const BuilderHrefItems = new Set<HTMLElement>()
export const BuilderBgImgItems = new Set<HTMLElement>()
/**
 * Utilize essa função em elementos onde a edição do texto precisa de um cuidado especial (<a>, elementos quebrados por <br/>)
 * Ao colocar o mouse em cima desses elementos um popup com o nome do elemento vai aparecer. ao clicar no popup o usuario vai ser direcionado a um campo onde ele pode escrever o novo valor para o campo em uma area segura (input)
 * @param field_name Campo de texto na `empresa`
 * @name HoverTextBuilder
 * @returns Props a serem descronstruidos no elemento
 */
export const HoverTextBuilder = (field_name:string) => {
    return {
        b_str: field_name,
        is_hover: 'true',
        ref: ref => {
            console.log(ref)
            if (ref) {
                BuilderTextHoverItems.add(ref)
            }
        }
    }
}
/**
 * @see HoverTextBuilder
 */
export const HBT = HoverTextBuilder;

export const HoverTextHrefBuilder = (field_name:string) => {
    return {
        b_str: field_name,
        b_href: 'h_'+field_name,
        is_hover: 'true',
        ref: ref => {
            if (ref) {
                BuilderTextHoverItems.add(ref)
                BuilderHrefItems.add(ref)
            }
        }
    }
}
/**
 * @see HoverTextHrefBuilder
 */
export const HTHB = HoverTextHrefBuilder;

export const BuilderImg = (field_name:string, default_img_url?: string) => {
    //const new_src = global.__EMPRESA_DATA__[field_name] || default_img_url;
    const new_src = default_img_url;
    let return_props:any = {
        b_img: field_name,
        ref: ref => {
            if (ref) {
                BuilderImgItems.add(ref)
            }
        },
    }
    if (new_src) {
        return_props.src = new_src
    }
    return return_props
}

/**
 * Utilize essa função caso seja necessário enviar uma imagem para o objeto da empresa porém o elemento que ela esta vinculada não é um <img/>
 * @param field_name 
 * @returns 
 */
export const BuilderBackgroundImg = (field_name:string) => {
    let return_props:any = {
        b_img: field_name,
        ref: ref => {
            if (ref) {
                BuilderBgImgItems.add(ref)
            }
        },
    }
    return return_props
}
