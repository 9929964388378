import React, { Component, useContext, useEffect, useRef, useState } from "react";
import {
  Empresa,
} from "smart-imob-types";
import { BuilderTextHoverItems, BuilderImgItems, BuilderHrefItems, BuilderBgImgItems, BuilderBackgroundImg } from '../builder-context'
import EmpresaContext from "../context";
import Styled from "styled-components";
import { post as client_post } from '../client_api';

interface ElementWithComputedStyleMap extends HTMLElement {
    computedStyleMap?: Function
    value?: any
}

declare global {
    interface Window {
        __EMPRESA_DATA__: Empresa;
        __IS_BUILDER_ACTIVE__?: boolean
        canusewebp?: boolean
    }
    namespace NodeJS {
      interface Global {
        __EMPRESA_DATA__: Empresa;
      } 
    }
}

export default function BuilderInterfaceComp() {
    const empresa = useContext(EmpresaContext)
    global.__EMPRESA_DATA__ = empresa;
    const [builder_ativo, setBuilder_ativo] = useState(false)
    const set_empresa = empresa.setEmpresa;
    
    useEffect(() => {
      // @ts-ignore
      window.BuilderTextHoverItems = BuilderTextHoverItems
      // @ts-ignore
      window.BuilderImgItems = BuilderImgItems
      // @ts-ignore
      window.BuilderHrefItems = BuilderHrefItems
      // @ts-ignore
      window.BuilderBgImgItems = BuilderBgImgItems
  
      window.__EMPRESA_DATA__ = empresa
  
      if (location.href.includes('localhost') || location.href.includes('builder_active')) {
        window.addEventListener('keydown', event => {
          if (event.key === 'F9') {
              setBuilder_ativo(S => !S)
          }
        })
      }
    }, [])

    const [Rect, setRect] = useState<DOMRect | null>(null)
    const [Focus, setFocus] = useState<ElementWithComputedStyleMap | null>(null)
    const [WidthUnit, setWidthUnit] = useState(null)
    const [Loading, setLoading] = useState(false)
    const [DontShowHelper, setHelper] = useState(false)

    useEffect(() => {
        if (Focus && Focus.computedStyleMap) {
            setWidthUnit(Focus.computedStyleMap().get('width'))
        } else setWidthUnit(null)
    }, [Focus])
    
    const isHover = () => Focus && Focus.getAttribute('is_hover') ? 'isHover' : ''
    const isImg = () => Focus && Focus.getAttribute('b_img');
    const isHref = () => Focus && Focus.getAttribute('b_href');
    const field = () => Focus && (Focus.getAttribute('b_str') || Focus.getAttribute('b_img')) || ''

    const toggleHelper = () => setHelper(v => !v)
    const field_change_ref = useRef<ElementWithComputedStyleMap>(null)
    const href_field_ref = useRef<ElementWithComputedStyleMap>(null)
    
    const handleChangeHover = async () => {
      if (DontShowHelper) {
        toggleHelper()
      }
      setTimeout(() => {
        if (field_change_ref) {
          if (field_change_ref.current) {
            field_change_ref.current.focus()
            togglePin()
          }
        }
      }, 30)
    }
    const getRect = (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
      return {
        top: rect.top + window.scrollY,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        x: rect.x,
        y: rect.y
      } as DOMRect;
    }
    const setRectFromElement = (element: HTMLElement) => {
      setRect(getRect(element))
    }
    const builder_items = [...Array.from(BuilderTextHoverItems), ...Array.from(BuilderImgItems), ...Array.from(BuilderHrefItems), ...Array.from(BuilderBgImgItems)]

    useEffect(() => {
        for (const element of Array.from(BuilderTextHoverItems)) {
            if (!element) continue;
            if (element.getAttribute('mounted')) continue;
            element.setAttribute('mounted','true')
            element.addEventListener('mouseenter', async (evt) => {
              if (Pin.current === 0) {
                setFocus(element)
                setRectFromElement(element)
              }
            });
        }

        for (const element of [...Array.from(BuilderImgItems), ...Array.from(BuilderBgImgItems)]) {
            if (!element) continue;
            if (element.getAttribute('mounted')) continue;
            element.setAttribute('mounted','true')
            element.addEventListener('mouseenter', async (evt) => {
              if (Pin.current === 0) {
                setFocus(element)
                setRectFromElement(element)
              }
            });
            element.addEventListener('click', async (evt) => {
                setLoading(true)
                try {
                    const { ctrlKey, shiftKey } = evt;
                    if (shiftKey) {
                        const field = element.getAttribute('b_img')
                        if (field) {
                          await client_post(empresa.url, 'edit_img_remove', { b_img: field });
                          if (element.tagName === 'IMG') {
                            (element as HTMLImageElement).src = '';
                          }
                          set_empresa({ [field]: null })
                          setLoading(false)
                        }
                    }
                    if (ctrlKey) { 
                        
                        const ephemeral_input = document.createElement('input');
                        ephemeral_input.style.display='none';
                        ephemeral_input.type='file';
                        ephemeral_input.name='file';
                        
                        let promise:Promise<any> = new Promise(() => {});
                        ephemeral_input.onchange = async () => {
                            if (ephemeral_input && ephemeral_input.files) {
                                const file = ephemeral_input.files[0]
                                const field = element.getAttribute('b_img')
                                if (field) {
                                    const formData = new FormData()
                                    formData.append(field, file)
                                    
                                    promise = client_post(empresa.url, 'edit_img', formData, { notJson: true })
                                    promise.then(() => setLoading(false))
                                    const { url } = await promise;
                        
                                    if (element.tagName === 'IMG') {
                                      (element as HTMLImageElement).src = '';
                                    }
                                    set_empresa({ [field]: url })
                                }
                            }
                        }
                        ephemeral_input.click();
                        return 
                    }
                } catch (error) {
                    alert('Algo deu errado, verifique o console em F12 -> Console')
                    setLoading(false)
                } finally {
                    console.log('fin')
                    setLoading(false)
                }
            });
        }
    }, [builder_ativo]);

    const [Anotacao, setAnotacao] = useState(false);
    const toggleAnotacao = () => setAnotacao(s => !s);
    
    const salvarField = async () => {
        setLoading(true)
        try {
            if (href_field_ref && href_field_ref.current) {
                if (href_field_ref.current.value !== undefined) {
                    const change = {
                        ['h_'+field()]: href_field_ref.current.value || ''
                    }
                    await client_post(empresa.url, 'edit_str', change)
                    set_empresa({ ...change })
                }
            }
            if (field_change_ref && field_change_ref.current) {
                if (field_change_ref.current.value !== undefined) {
                    const change = {
                        [field()]: field_change_ref.current.value || ''
                    }
                    await client_post(empresa.url, 'edit_str', change)
                    set_empresa({ ...change })
                }
            }
        } catch (error) {
            console.error(error)
            alert('Algo deu errado. Mais informações sobre o erro em: F12 -> Console')
        }
        setLoading(false)
    }

    
    const scrollToEl = (field?) => {
        if (Rect) window.scrollTo(Rect.x, Rect.top - (window.scrollY/3.6))
    }
    
    const elementFromField = (field_name:string) => {
        return builder_items.find(item => item.getAttribute('b_str') === field_name || item.getAttribute('b_img') === field_name)
    }
    const handleChangeSelectCampos = (e) => {
        const element = elementFromField(e.target.value)
        if (element) {
          setFocus(element)
          setRectFromElement(element)
        }
    }

    const simulateCtrlImg = () => {
      if (Focus && isImg()) {
        setLoading(true)
        const evt = new PointerEvent("click", {
          ctrlKey: true
        });

        Focus.dispatchEvent(evt)
        setLoading(false)
      }
    }
    const simulateShiftImg = () => {
      if (Focus && isImg()) {
        setLoading(true)
        const evt = new PointerEvent("click", {
          shiftKey: true
        });

        Focus.dispatchEvent(evt)
        setLoading(false)
      }
    }

    const [VisiblePin, setVisiblePin] = useState(false)
    const Pin = useRef(0)
    const togglePin = () => {
      if (Pin.current === 0) {
        Pin.current++
        setVisiblePin(true)
      } else {
        Pin.current = 0
        setVisiblePin(false)
      }
    };

    if (!builder_ativo) return null;
    return <BuilderStyled>
        {
            (Focus && Rect) && 
            <div
                className={`editor_outside ${isHover()} ${Loading ? 'loading' : ''} ${VisiblePin ? ' pin' : ''}`} 
                style={{ top: Rect.top-5, left: Rect.left-5, width: Rect.width+10, height: Rect.height+10 }}
            >
                <div className="editor_title">{isHover() ? <u onClick={handleChangeHover}>{VisiblePin ? 'PINADO - ' : ''}Clique aqui para editar</u> : null}<b>{field()}</b> {Loading && <u>Salvando...</u>} </div>
                {
                isImg() && Rect.width && Rect.height && <div
                  className="editor_title r"
                >Largura: {Math.round(Rect.width)} pixeis{WidthUnit === 'percent' ? ' (Relativo ao tamanho da tela)' : ''}, Altura: {Math.round(Rect.height)} pixeis </div>
                }
            </div>
            }
            {
              Anotacao && builder_items.map((item, i) => {
                const ItemRect = getRect(item);
                const ItemIsImg = () => item.getAttribute('b_img');
            
                const ItemField = () => (item.getAttribute('b_str') || item.getAttribute('b_img')) || ''
                return <div
                  key={ItemField()}
                  className={`editor_outside anotado ${ItemField() === field() ? 'hidden' : ''}`} 
                  style={{ top: ItemRect.top-5, left: ItemRect.left-5, width: ItemRect.width+10, height: ItemRect.height+10 }}
                >
                    <div className="editor_title"><b>{ItemField()}</b> </div>
                    {
                      ItemIsImg() && ItemRect.width && ItemRect.height && <div
                        className="editor_title r"
                      >Largura: {Math.round(ItemRect.width)} pixeis{WidthUnit === 'percent' ? ' (Relativo ao tamanho da tela)' : ''}, Altura: {Math.round(ItemRect.height)} pixeis </div>
                    }
                </div>
              })
            }
            <div className={`builder-instructions ${DontShowHelper ? 'Helper' : ''}`}>
              <div className="ins-sec">
                  <h5>Como usar?</h5>
                  <p>
                  Para <b>alterar uma imagem</b> clique na imagem com o <b>CTRL pressionado</b>. <br/>
                  Para voltar uma imagem para a URL padrão clique na imagem com o SHIFT pressionado. <br/>
                  Ao trocar de página pressione F9 novamente para recarregar o builder
                  </p>
              </div>
              <div className="ins-sec">
                <div>
                  <button onClick={toggleAnotacao}>{Anotacao ? 'Desativar' : 'Ativar'} anotação</button>
                </div>
                {
                  VisiblePin && <div>
                    <button onClick={togglePin} style={{marginTop: 2}}>Desativar pin</button>
                  </div>
                }
                <div>
                  <select defaultValue={field()} onChange={handleChangeSelectCampos}>
                    <option>Campos disponiveis</option>
                    {
                      builder_items.map((item, i) => {
                          const val = item.getAttribute('b_str') || item.getAttribute('b_img')
                          const isImg = item.getAttribute('b_img')
                          if (!val) return;
                          return <option className={isImg ? 'isImg' : ""} key={val} value={val}>{val}</option>
                      })
                    }
                  </select>
                </div>
                <div>
                  <div>
                    <b>Imagens editaveis:</b> {BuilderImgItems.size + BuilderBgImgItems.size}
                  </div>
                  <div>
                    <b>Textos editaveis:</b> {BuilderTextHoverItems.size}
                  </div>
                  <div>
                    <b>Links editaveis:</b> {BuilderHrefItems.size}
                  </div>
                </div>
              </div>
              <div className="ins-sec">
                  <h5>Edite <i onClick={scrollToEl}>{field() || 'um campo'}</i></h5>
                  {
                  !isImg() ? <>
                  
                      <textarea
                      style={{width: '100%', minHeight: 160, maxHeight: 600, padding: '4px 8px'}}
                      // @ts-ignore
                      ref={field_change_ref} 
                      defaultValue={empresa[field()]}
                      />
                      {
                          // @ts-ignore
                      isHref() && <input ref={href_field_ref} placeholder="Link desse campo" defaultValue={empresa['h_'+field()]} />
                      }
                      <button disabled={Loading} onClick={salvarField}>Confirmar edição</button>
                  </> : <>
                    <button disabled={Loading} onClick={simulateCtrlImg}>Alterar imagem</button>
                    <button disabled={Loading} onClick={simulateShiftImg}>Resetar imagem</button>
                  </>
                  }
              </div>
            <div className="close d-flex" onClick={toggleHelper}>{DontShowHelper ? '+' : 'x'}</div>
            </div>
    </BuilderStyled>
}

const BuilderStyled = Styled.div`
    .builder_toggle_vis {
    position: absolute;
    left: 0;
    bottom: 0;
  
    cursor: pointer;
    margin: 10px;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
    background: #ff5a3c;
    color: white;
    font-weight: 600;
  }
  .builder-instructions {
    position: fixed;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    background: #ff5a3c;
    padding: 10px;
    z-index: 1000;
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    &.Helper {
      background:transparent;
      & .ins-sec {
        display: none;
      }
    }
    & button {
      border: none;
      cursor: pointer;
      border-radius: 2px;
    }
    & button:focus {
      background: #ff8a2b;
      color: white;
    }
    & option.isImg {
      background: #dddddd;
    }
    & .ins-sec {
      padding: 10px;
      width: calc(100% / 3);
    }
    .close {
      position: absolute;
      justify-content: center;
      align-items: center;
      background-color: rgb(0, 0, 0, 22%);
      left: 0;
      top: 0;
      transform: translate(-50%,-50%);
      padding: 10px;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    h5, p, div {
      color: white;
    }
  }
  .builder_section_container {
    position: relative;
    &.hidden {
      opacity: 0.7;
    }
  }
  .b_style {
      outline: 0;
    }
    .b_style[b_str]:empty::after {
        content: 'Campo vazio';
        opacity: 0.6;
    }
    .editor_outside {
      &.pin {
        border-color: #dc1c56;
        .editor_title {
          background-color: #dc1c56;
        }
      }
      &.anotado {
        z-index: +8;
        opacity: 0.7;
        &.hidden {display: none};
        .editor_title {
          bottom: unset;
          top: 0%;
          transform: translateY(-50%);
          border-radius: 3px;
          right: 2%;
          left: unset;
          &.r {
            top: 1%;
            transform: translateY(50%);
          }
        }
      }
      position: absolute;
      z-index: +10;
      border-radius: 3px;
      border: 2px solid #DC1C2E;
      pointer-events: none;
      &.isHover {
        user-select: none;
        pointer-events: all;
        u {
          cursor: pointer;
          margin-right: 4px;
        }
      }
      &.loading {
        opacity: 0.5;
      }
      animation: 100ms fade forwards;
    }
    .editor_title {
      position: absolute;
      text-transform: capitalize;
      background-color: #DC1C2E;
      font-size: 10px;
      left: 2%;
      bottom: 100%;
      padding: 4px 8px 2px;
      color: #fff;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      &.r {
        top: 100%;
        bottom: unset;
      }
    }
    .editor_upload {
      position: absolute;
      background-color: #DC1C2E;
      &.loading {
        opacity: 0.5;
      }
      &:hover {
        background-color: #ff3d48;
      }
      border-radius: 3px;
      left: 2%;
      top: 0%;
      z-index: +2;
      color: #fff;
      padding: 6px 8px;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-content: center;
      align-items: center;
      margin: 5px;
      box-shadow: 0px 2px 6px rgb(15, 76, 92, 22%);
      & svg {
        margin-right: 4px;
      }
      & input {
        visibility: hidden;
        width: 0;
        height: 0;
        pointer-events: none;
      }
    }
`