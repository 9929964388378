import React from "react";
import Spinner from "react-spinner-material";

const Loading = props => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: props.height || "50vh"
    }}
  >
    <Spinner
      radius={30}
      color={"var(--main-color-one)"}
      stroke={3}
      visible={true}
    />
  </div>
);

export default Loading;
