/* eslint-disable */
import get from "../client_api";
import {
  Imóvel,
  Empreendimento,
  ImoveisInfo as ImoveisInfoType
} from "smart-imob-types";

const formatter = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

const formatterDolar = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const formatterEuro = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR"
});

export const toBRL = (value: number | bigint, type?: string) => {
  if (!type) return formatter.format(value);
  if (type === "Real") return formatter.format(value);
  if (type === "Dolar") return formatterDolar.format(value);
  if (type === "Euro") return formatterEuro.format(value);
};

/**
 * Mounts the url to property listing.
 * @param {string} url - The url from the client.
 * @param {string} tipo - params that will be used on the search.
 * @param {string} value - params that will be used on the search.
 * @param {number} selected - params that will be used on the search.
 */
export const LinkMounterSingleSelect = (url, tipo, value, selected) => {
  const link = url;
  let valueFormated;
  if (typeof value === "string") {
    valueFormated = value
      .replace(/\//g, "_")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ /g, "-");
  } else {
    valueFormated = value;
  }
  if (selected === 0) {
    return url + `/transacao/venda/${tipo}/${valueFormated}`;
  }
  if (selected === 1) {
    return url + `/transacao/locacao/${tipo}/${valueFormated}`;
  }
  return link + `/${tipo}/${valueFormated}`;
};

/**
 * Tests to see if browser supports webp format.
 */
export const support_format_webp = () => {
  try {
    const elem = document.createElement("canvas");

    if (elem.getContext && elem.getContext("2d")) {
      // was able or not to get WebP representation
      return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    } else {
      // very old browser like IE 8, canvas not supported
      return false;
    }
  } catch (error) {
    // console.log('erro no banner: ', error)
    return false;
  }
};

export const formatPhone = phone => {
  return phone
    .toString()
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
    .replace(" ", "");
};

export const isMobile = () => {
  // verifica se é mobile
  if (document) {
    const mobileTest = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileTest.test(navigator.userAgent);
  }
};

export const isIOS = () => {
  if (navigator) {
    let platform = navigator.userAgent || navigator.platform || "unknown";

    return /iPhone|iPod|iPad/.test(platform);
  }
};

export const getImage = (
  imovel: Imóvel | Empreendimento,
  morePhotos = false
) => {
  let imgSrc;
  let imgSrcWebp;
  let SortedFotos = imovel.fotos
    ? imovel.fotos.sort(
        (a, b) =>
          a.destaque
            ? -1
            : parseInt(String(a.ordem)) - parseInt(String(b.ordem))
      )
    : [];
  const fotoDestaque = imovel.fotos
    ? imovel.fotos.find(e => e.destaque === true)
    : "";
  if (morePhotos) {
    try {
      const othersPhotos = SortedFotos.slice(0, 4);
      imgSrcWebp = othersPhotos[0].resized_webp
        ? othersPhotos.map(photo => photo.resized_webp)
        : othersPhotos.map(photo => photo.source.uri_webp);
      imgSrc = othersPhotos[0].resized
        ? othersPhotos.map(photo => photo.resized)
        : othersPhotos.map(photo => photo.source.uri);
      return [imgSrc, imgSrcWebp];
    } catch (_) {
      imgSrc =
        "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/placeholders%2Fproperty-placeholder.png?alt=media&token=96ef2786-f47a-484c-aa76-82880bd20b01";
      return [[imgSrc], [""]];
    }
  }
  try {
    imgSrcWebp = fotoDestaque ? fotoDestaque.source.uri_webp || "" : "";
    imgSrc = fotoDestaque
      ? fotoDestaque.resized || fotoDestaque.source.uri
      : SortedFotos[0].resized || SortedFotos[0].source.uri;
    return [imgSrc, imgSrcWebp];
  } catch (_) {
    imgSrc =
      "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/placeholders%2Fproperty-placeholder.png?alt=media&token=96ef2786-f47a-484c-aa76-82880bd20b01";
    return [imgSrc, ""];
  }
};

/**
 * Get the featured image.
 * @Returns {string} featured image.
 * @todo make the function 😅
 */
export const getFeaturedImage = (fotos, not_resized = false) => {
  let imgSrc;
  let imgSrcWebp;
  const fotoDestaque = fotos.find(e => e.destaque === true);
  if (not_resized) {
    imgSrcWebp = fotoDestaque
      ? fotoDestaque.uri_webp
      : fotos.length > 0
        ? fotos[0].source.uri_webp
        : "";
    imgSrc = fotoDestaque
      ? fotoDestaque.source.uri
      : fotos && fotos.length > 0
        ? fotos[0].source.uri
        : "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/GD_imgSemImagem.png?alt=media&token=33cc62be-97fb-4675-8161-bd2495faf193";
    return [imgSrc, imgSrcWebp];
  }
  try {
    imgSrcWebp = fotoDestaque ? fotoDestaque.resized_webp || "" : "";
    imgSrc = fotoDestaque
      ? fotoDestaque.resized
      : fotos && fotos.length > 0
        ? fotos[0].resized || fotos[0].source.uri
        : "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/GD_imgSemImagem.png?alt=media&token=33cc62be-97fb-4675-8161-bd2495faf193";
  } catch (_) {
    imgSrc = "";
  }
  return [imgSrc, imgSrcWebp];
};

// Debounce Function -> doesn't active some functions like window.scroll every scroll;
export const debounce = function(func, wait, immediate = false) {
  let timeout;
  return function(...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    // @ts-ignore
    const context = this;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const PropertyLink = (imovel: Imóvel) => {
  let link = `/imovel/${imovel.db_id}`;

  if (imovel.suítes && imovel.suítes !== "0") {
    link = "/" + imovel.suítes + "-Suite" + link;
  }
  if (imovel.vagas && imovel.vagas !== "0") {
    link = "/" + imovel.vagas + "-Vaga" + link;
  }
  if (imovel.dormitórios && imovel.dormitórios !== "0") {
    link = "/" + imovel.dormitórios + "-Dormitorio" + link;
  }
  if (imovel.banheiros && imovel.banheiros !== "0") {
    link = "/" + imovel.banheiros + "-Banheiro" + link;
  }

  if (imovel.cidade && imovel.cidade.nome) {
    link = "/" + imovel.cidade.nome.replace(/ /g, "-") + link;
  }
  if (imovel.tipo) {
    link = "/" + imovel.tipo.replace(/ /g, "-") + link;
  }

  let isVenda =
    imovel.venda &&
    typeof imovel.preço_venda === "number" &&
    imovel.preço_venda > 0;
  let isLocacao =
    imovel.locação &&
    typeof imovel.locação === "number" &&
    imovel.preço_locação > 0;
  if (isVenda && isLocacao) {
    link = "/venda_e_locacao" + link;
  } else if (isVenda) {
    link = "/venda" + link;
  } else if (isLocacao) {
    link = "/locação" + link;
  }
  return link;
};

export const compare = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export async function getImoveisInfo(url: string | string[]) {
  const imoveisInfo: ImoveisInfoType = await get(url, `/imoveis_info`);

  const estados = new Map();
  const cidades = new Map();
  const bairros = imoveisInfo.bairros_disponiveis;

  if (bairros) {
    for (const local of bairros) {
      const { bairro, info } = local;
      const { cidade, estado } = info;
      if (cidade)
        cidades.set(cidade.id || cidade.value, {
          cidade_nome: cidade.nome,
          estado: estado
        });
      if (estado) estados.set(estado.id || estado.value, estado.nome);
    }
  }

  const estados_array = Array.from(estados)
    .map(([estado_id, estado_nome]) => ({
      value: estado_id,
      label: estado_nome
    }))
    .sort((a, b) => compare(a.value, b.value));

  const cidades_array = Array.from(cidades)
    .map(([cidade_id, { cidade_nome, estado }]) => ({
      value: cidade_id,
      label: cidade_nome,
      estado
    }))
    .sort((a, b) => compare(a.value, b.value));

  const bairros_array = bairros.map(({ bairro, info }) => ({
    value: bairro,
    label: bairro,
    info
  }));

  let tipos_array = imoveisInfo.tipos
    .map(tipo => ({
      value: tipo,
      label: tipo
    }))
    .sort((a, b) => compare(a.value, b.value));

  const imoveisInfoInputs = {
    ...imoveisInfo,
    estados_array,
    cidades_array,
    bairros_array,
    tipos_array
  };

  return imoveisInfoInputs || {};
}
