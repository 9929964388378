import { combineReducers } from 'redux';

import auth from './auth';
import profile from './profile';
import empresa from './empresa';

export default combineReducers({
  empresa,
  auth,
  profile
});
