/* eslint-disable import/first */
/* eslint-disable no-unused-expressions */
import React, { createContext } from "react";
import { Empresa, Cliente } from "smart-imob-types";

const EmpresaContext = React.createContext<Empresa>({
  setEmpresa: () => {},
  anuncio: [],
  caracteristicas_disponiveis: [],
  bairros_disponiveis: [],
  db_id: "",
  desc_imovel_text: "",
  banner: [],
  banner_webp: [],
  banner_mobile: "",
  banner_mobile_webp: "",
  url: [],
  imoveis_total_tipos: {},
  logo: "",
  logo_escrita: "",
  footer_logo: "",
  favicon: "",
  redes_sociais: [],
  nome_fantasia: "",
  nome: "",
  endereço: "",
  depoimentos: [],
  tipos_disponiveis: [],
  video_mobile: null,
  video_topo: null,
  frase_site: "",
  frase_lancamentos: "",
  frase_destaques: "",
  possui_locacao: true,
  possui_temporada: false,
  logo_use_bg: true,
  imovel_use_bg: true,
  envie_seu_imovel: false,
  ocultar_marca_agua: false,
  ocultar_equipe: false,
  ocultar_corretor: false,
  mostrar_campos_personalizados: false,
  palleta: {
    cor_primaria: "",
    cor_secundaria: "",
    cor_secundária: "",
    cor_auxiliar: "",
    cor_rodape: "",
    cor_texto_rodape_1: "",
    cor_texto_rodape_2: "",
    use_color: false
  }
});

type Config = {
  empresa_id: string;
  cliente: Cliente;
  roleta?: boolean;
};

export const EnviarContato = async (
  config: Config
): Promise<string | Cliente> => {
  try {
    const { nome, telefone, DDD } = config.cliente;
    const email = config.cliente.email.toLowerCase();
    if (!nome || !email || !telefone || !DDD) {
      alert(
        "Algum campo no formulário de cadastro não foi preenchido corretamente"
      );
      return config.cliente;
    }
    if (config.roleta) {
      config.cliente.corretor_responsavel = null;
    }
    if (telefone.length < 7) {
      alert("O número de telefone não está em um formato aceitavel!");
      return config.cliente;
    }

    const rawResponse = await fetch(
      "https://us-central1-smartimob-dev-test.cloudfunctions.net/RegistrarCliente",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...config.cliente,
          empresa: config.empresa_id,
          email: email
        })
      }
    );
    const res = await rawResponse.json();

    // console.log(config.empresa_id, nome, email, telefone, DDD);
    // console.log("resposta do JSON: ", res);
    if (config.empresa_id === "XH8SQsRZB6oSxfoQ9oNm") {
      sessionStorage.setItem("uid", res.id);
    } else {
      localStorage.setItem("uid", res.id);
    }

    return res.id;
  } catch (error) {
    console.log(error);
    alert("Um erro inesperado ocorreu ao tentar enviar um contato!");
    return config.cliente;
  }
};

export default EmpresaContext;
