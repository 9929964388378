import React, { useEffect, useState, useContext } from "react";
import EmpresaContext from "../context";
import Styled from "styled-components";

const MaintenanceStyle = Styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 10000;
  background: #00000080;
  .card-container {
    background: #fff;
    padding: 40px;
    border-radius: 5px;
    text-align: center;
  }
`;

const Maintenance: React.FC = props => {
  let context = useContext(EmpresaContext);
  const [Maintenance, setMaintenance] = useState(false);

  const onAccept = () => {
    localStorage.setItem("manutencao", "true");
    setMaintenance(true);
  };

  useEffect(() => {
    if (localStorage.getItem("manutencao")) {
      setMaintenance(true);
    }
  }, []);
  if (Maintenance) return null;
  return (
    <MaintenanceStyle>
      <div className="card-container">
        <i
          style={{
            color: context.palleta.cor_primaria || "#999",
            fontSize: "45px"
          }}
          className="fa fa-exclamation-triangle"
        />
        <h2>O Site está em manutenção</h2>
        <p>Qualquer informação sobre algum imóvel, entrar em contato!</p>
        {/* <p>
            {context.telefones_empresa &&
              context.telefones_empresa.map(
                telefone => `${telefone.telefone} `
              )}
          </p> */}
        <div className="btn btn-yellow" onClick={onAccept}>
          Ok, entendi!
        </div>
      </div>
    </MaintenanceStyle>
  );
};

export default Maintenance;
