/**
 *
 * @param {string} url
 * @param {string | "preco" | "imovel_codigo/:codigo" | "agenciador_info/:id" | "imovel/:id" | "empreendimento/:id" | "imoveis_destaque" | "ultimos_imoveis" | "imoveis_preco_semelhante"} EntryPoint
 */
export default async function get(url, EntryPoint) {
  const isDevH =
    typeof window === "undefined"
      ? url.includes("localhost:3")
      : window.location.href.includes("localhost:3");

  const newUrl = isDevH
    ? "http://localhost:8080"
    : url.includes("localhost")
      ? url.includes("http")
        ? url
        : "http://" + url
      : url.includes("https")
        ? url
        : "https://" + url;
  const res = await fetch(newUrl + "/api/" + EntryPoint);
  const result = await res.json();
  return result;
}

export async function post(url, EntryPoint, body, options) {
  const isDevH =
    typeof window === "undefined"
      ? url.includes("localhost:3")
      : window.location.href.includes("localhost:3");

  const newUrl = isDevH
    ? "http://localhost:8080"
    : url.includes("localhost")
      ? url.includes("http")
        ? url
        : "http://" + url
      : url.includes("https")
        ? url
        : "https://" + url;
  const request_obj = {
    method: "post",
    body: options && options.notJson ? body : JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
  if (options && options.notJson) {
    delete request_obj.headers["Content-Type"];
  }
  try {
    if (typeof window !== "undefined") {
      request_obj.headers["X-Senha"] = localStorage.getItem("senha-builder");
    }
  } catch (error) {}
  const res = await fetch(newUrl + "/api/" + EntryPoint, request_obj);
  const result = await res.json();
  return result;
}
